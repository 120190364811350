export default (
  { req, res, route, app, store, $axios, $clerk, $cookies, redirect, env },
  inject
) => {
  const sendProduct = async (email, barcode) => {
    try {
      await $axios.post(`/mailer/prepareMail`, {
        origin: "product",
        to: email,
        store_id: store.getters.store.store_id,
        barcode: barcode,
      });
      $clerk.hyperAlert({
        time: 6000,
        title: "Το προϊόν στάλθηκε",
        icon: "success",
      });
    } catch (error) {
      console.error(error);
      $clerk.hyperAlert({
        time: 6000,
        title: "Η αποστολή απέτυχε",
        icon: "warning",
      });
    }
  };
  const prepareItems = async (items) => {
    const prepareSingle = (item) => {
      if (item.id) {
        item._id = item.id;
      }
      if (!item._id) {
        item._id = item.barcode;
      }
      if (!item.availability) {
        item.availability = {};
      }

      if (!item.quantity) {
        item.quantity = 1;
      }
      if (!item.images) {
        item.images = [];
      }
      item.images = item.images.filter(i=>i)
      if (!item.selectedFavorite) {
        item.selectedFavorite = 1;
      }
      if (!item.cartItem) {
        item.cartItem = {};
      }
      item.imagesLength = (item.images || []).length;
      if ((store.getters.user || {}).favorites) {
        item.inFavorites = (
          (store.getters.user || {}).favorites || []
        ).includes(item.barcode);
      }
      if (!item.prices) {
        item.prices = {
          discount: 0,
          retail_price: 0,
          price: 0,
        };
      } else if (item.prices) {
        item.prices.discount = parseFloat(item.prices.discount);
        if (item.prices.fixed_price) {
          item.prices.discount = 10;
        }
        if (item.prices.fixed_price) {
          item.prices.discount = 10;
        } else {
          item.prices.discount = parseFloat(item.prices.discount);
        }
      }
      if (!item.shipping) {
        try {
          let courierCost = store.getters.store.eshop.shippings
            .filter((s) => s.cost && s.type == "courier")
            .sort((a, b) => a.cost - b.cost)[0].cost;
          let cost =
            item.prices.price < store.getters.store.eshop.var.free_shipping
              ? courierCost
              : 0;
          item.shipping = { cost };
        } catch (error) {}
      }
      if (!item.tags) {
        item.tags = [];
      }
      if (item.moq) {
        item.quantity = item.moq;
      }
      let options = [];
      let query_tags = {};
      let flat_tags = {};
      (item.tags || []).forEach((o) => {
        if (o.option) {
          let found = false;
          options.forEach((op) => {
            if (op.key == o.key) {
              if (!op.required) {
                op.required = o.required;
              }
              found = true;
              op.values.push({
                ...(o || {}),
              });
            }
          });
          if (!found) {
            options.push({
              key: o.key,
              required: o.required,
              values: [
                {
                  ...(o || {}),
                },
              ],
            });
          }
        } else {
          if (Array.isArray(o.value)) {
            query_tags[`tags_${o.key}`] = (o.value[0] || {}).name;
          } else {
            query_tags[`tags_${o.key}`] = o.value;
          }
        }
      });
      item.options = options;
      (item.tags || []).forEach((t) => {
        flat_tags[t.key] = t.value;
      });
      item.flat_tags = flat_tags;
      if (item.store) {
        item.store = {
          _id: item.store._id,
          id: item.store._id,
          store_id: item.store._id,
        };
      }
      if (!item.promo) {
        item.promo = {};
      }
      item = {
        ...item,
        ...query_tags,
      };
      return item;
    };
    items = items.filter((i) => i.prices);
    return items.map((item) => prepareSingle(item));
  };
  const actualPrice = (i, fixed, q = 1) => {
    let retail_price = (i.prices || {}).retail_price;
    let final_price;
    if (i.variation) {
      let additions = 0;
      i.variation.forEach((v) => {
        if (v && v.price) {
          if (v.type == "addition") {
            additions += parseFloat(v.price);
          } else if (v.type == "price") {
            retail_price = parseFloat(v.price);
          }
        }
      });
      retail_price += parseFloat(additions);
      i.prices.mixed_price = retail_price;
    }
    if (i.prices.discount) {
      if (fixed) {
        final_price = parseFloat(
          (retail_price - retail_price * (i.prices.discount / 100)) * q
        ).toFixed(2);
      } else {
        final_price = parseFloat(
          (retail_price - retail_price * (i.prices.discount / 100)) * q
        );
      }
    } else {
      if (fixed) {
        final_price = parseFloat(retail_price * q).toFixed(2);
      } else {
        final_price = parseFloat(retail_price * q);
      }
    }
    i.prices.price = parseFloat(
      app.$hyperUtility.toSafeFixed(
        retail_price - retail_price * (i.prices.discount / 100),
        2
      )
    );
    return final_price;
  };

  const inFavorites = (item) => {
    item.inFavorites = ((store.getters.user || {}).favorites || []).includes(
      item.barcode
    );
    return item.inFavorites;
  };

  const toggleFavorite = async (i) => {
    if (!i) {
      i = store.getters.item;
    }
    if (!store.getters.token) {
      if (store.getters.store.eshop.favoriteAuth) {
        $nuxt.$emit(store.getters.store.eshop.favoriteAuth);
      } else {
        app.router.push({
          path: "/account",
        });
      }
    } else {
      try {
        let temp = await $axios.$post(
          `users/favorites`,
          {
            barcode: i.barcode,
            user_id: store.getters.user.id,
            store_id: store.getters.store.id,
          },
          {
            headers: {
              Authorization: `Bearer ${store.getters.token}`,
            },
          }
        );
        if (temp == "toggled") {
          let user = JSON.parse(JSON.stringify(store.getters.user));
          if ((user.favorites || []).includes(i.barcode)) {
            (user.favorites || []).splice(
              (user.favorites || []).indexOf(i.barcode),
              1
            );
          } else {
            (user.favorites || []).push(i.barcode);
            $nuxt.$emit("informant:addToWishlist", { item: i });
          }
          user = await app.$hyperUser.getFavorites(user);
          store.commit("user", user);
          i = inFavorites(i);
          $clerk.hyperAlert({
            time: 5000,
            icon: "success",
            title: "Αγαπημένα",
            html: `Τα αγαπημένα προϊόντα σας ενημερώθηκαν επιτυχώς.`,
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const getItems = async (search,mongo) => {
    try {
      let elastic = store.getters.store.eshop.search.includes("elastic") && !mongo
        ? "sv/"
        : "";
      let res = await $axios.$get(
        `items/${elastic}store/${store.getters.store.store_id}?store_id=${store.getters.store.store_id}&${search}`,
        {
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
          },
        }
      );
      if ((res.items || {}).total) {
        res.items.docs = res.items.docs.map((i) => {
          i.quantity = 1;
          i.prices.discount = parseFloat(i.prices.discount);
          if (i.prices.fixed_price) {
            i.prices.discount = 10;
          }
          return i;
        });
      }
      return res;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const getItem = async (id) => {
    try {
      let res = await $axios.$get(
        `items/store/${store.getters.store.store_id}?store_id=${store.getters.store.store_id}&q=${id}`,
        {
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
          },
        }
      );
      if (res.items.docs && res.items.docs[0].prices) {
        res.items.docs = res.items.docs.map((i) => {
          i.prices.discount = parseFloat(i.prices.discount);
          if (i.prices.fixed_price) {
            i.prices.discount = 10;
          }
          return i;
        });
      }
      return res;
    } catch (e) {
      return false;
    }
  };

  const discountCeil = (d) => {
    if (d) {
      return Math.round(d);
    }
    return d;
  };
  const promos = async (item, variable,pick) => {
    let rules = store.getters.store.eshop.rules;
    rules = rules.filter((r) => !r.disabled);
    let promos = [];
    try {
      rules = rules.filter((r) => (r.variables || []).includes(variable));
      rules.forEach((r) => {
        let valids = [];
        r.conditions.forEach((c) => {
          r.maximum = 1;
          if(c.type == 'cartRedeems'){
            r.maximum = parseInt(c.value || 1) || 1;
          }
          if(c.type == 'key'){
            let comparison = false;
            let value = item[c.key] || '';
            if (value && c.value !== undefined) {
              switch (c.comparison) {
                case "neq":
                  comparison = value != c.value;
                  break;
                case "lt":
                  comparison = value < c.value;
                  break;
                case "lte":
                  comparison = value <= c.value;
                  break;
                case "gt":
                  comparison = value > c.value;
                  break;
                case "gte":
                  comparison = value >= c.value;
                  break;
                case "*":
                  comparison = (value+'').toLowerCase().includes((c.value+'').toLowerCase());
                  break;
                case "!*":
                  comparison = !(value+'').toLowerCase().includes((c.value+'').toLowerCase());
                  break;
                case "*!":
                  comparison = (c.value+'').toLowerCase().includes((value+'').toLowerCase());
                  break;
                case "!*!":
                  comparison = !(c.value+'').toLowerCase().includes((value+'').toLowerCase());
                  break;
                default:
                  comparison = (c.value+'').toLowerCase() == (value+'').toLowerCase();
                  break;
              }
            } else if (c.value == "" || c.value === undefined) {
              comparison = true;
            }
            valids.push(comparison);
          }else if (c.params) {
            c.params.forEach((p) => {
              if (p.key == "barcodes" && p.value.includes(item.barcode)) {
                valids.push(r);
              } else if (p.key == "sku" && p.value.includes(item.sku)) {
                valids.push(r);
              } else if (p.key && (item[p.key]+'').toLowerCase() == (p.value+'').toLowerCase()) {
                valids.push(r);
              }
            });
            
          }
        });
        if (
          valids.length &&
          valids.every((v) => v) &&
          !promos.some((p) => p.uid == r.uid)
        ) {
          if (r.tags && !Array.isArray(r.tags)) {
            r.tags = r.tags.split(",");
          }
          promos.push(r);
        }
        for (const rr of r.results) {
          if (rr.type == "freeProduct") {
            let q = {};
            rr.params.forEach((p) => {
              if (
                p.key != "label" &&
                p.key != "build" &&
                p.key != "relative" &&
                p.key != "tag_value"
              ) {
                q[p.key] = p.value;
              }
            });
          }
        }
      });
      for (const p of promos) {
        let barcodes = (
          (p.results || []).find((r) => r.type == "freeProduct") || {}
        ).value;
        if (barcodes) {
          let gifts = await getItems(`barcodes=${barcodes}`,true);
          if (!(gifts.items||{}).total) {
            gifts = await getItems(`sku=${barcodes}`,true);
          }
          gifts = (gifts.items || {}).docs || [];
          p.gifts = gifts.map((g) => {
            g.selected = false;
            return g;
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
    
    if(pick){
      if (!((item || {}).promo||{}).uid && (promos||[]).length) {
        $nuxt.$emit('pick_gift',{item,promos,open:true})
      }
    }
    return promos;
  };

  inject("hyperItem", {
    sendProduct,
    prepareItems,
    actualPrice,
    toggleFavorite,
    inFavorites,
    getItems,
    getItem,
    discountCeil,
    promos,
  });
};
