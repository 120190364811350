export default (
  { req, res, route, app, store, $axios, $cookies, redirect, env, $clerk },
  inject
) => {
  const syncCart = async (cart) => {
    cart = store.getters.cart;
    localStorage.setItem("cart", JSON.stringify(cart));
  };
  const saveCart = async (cart, skip) => {
    cart = JSON.parse(JSON.stringify(cart));
    if (cart.then && typeof cart.then == "function") {
      cart = await cart;
    }
    if (!skip) {
      cart = await remoteCalculateCart(cart);
    }
    if (cart.then && typeof cart.then == "function") {
      cart = await cart;
    }
    if (store.getters.token) {
      cart.token = store.getters.token;
    }
    localStorage.setItem("cart", JSON.stringify(cart));
    store.commit("cart", cart);

    if ($nuxt) {
      $nuxt.$emit("updateCart");
    }
    //after some seconds
    $nuxt.$cookies.set("sum", cart.items.map((i) => i.barcode).join("_"));
    if (cart.then && typeof cart.then == "function") {
      return await cart;
    } else {
      return cart;
    }
  };
  const getRules = (cart) => {
    let rules = [];
    (store.getters.store.eshop.rules || []).forEach((cr) => {
      if ((cr.results || []).length) {
        rules.push(processRule(cart, cr));
      }
    });
    return rules;
  };

  const processRule = (cart, r) => {
    let time = Date.now();
    let valids = [];
    let labels = [];
    (r.conditions || []).forEach((c) => {
      if (c.type == "giftCard") {
        if ((cart.giftcard || {}).code == c.value) {
          valids.push(true);
        } else {
          valids.push(false);
        }
      } else if (c.type == "validFrom") {
        let from = new Date(c.value).getTime();
        if (time >= from) {
          valids.push(true);
        } else {
          valids.push(false);
        }
      } else if (c.type == "validTo") {
        let to = new Date(c.value).getTime();
        if (time < to) {
          valids.push(true);
        } else {
          valids.push(false);
        }
      } else if (c.type == "totalCost") {
        if (cart.totalC + cart.shipping.cost + cart.payment.cost > c.value) {
          valids.push(true);
        } else {
          valids.push(false);
        }
      } else if (c.type == "productCost") {
        if (cart.totalC > c.value) {
          valids.push(true);
        } else {
          valids.push(false);
        }
      } else if (c.type == "items") {
        valids.push(
          !!(
            (cart.items || []).filter((i) => c.value.includes(i.barcode)) || []
          ).length
        );
      } else if (c.type == "key") {
        valids.push(
          !!((cart.items || []).filter((i) => i[c.key] == c.value) || []).length
        );
      } else if (c.type == "emails") {
        if (!store.getters.token) {
          valids.push(false);
        } else if ((c.value || "").includes((store.getters.user || {}).email)) {
          valids.push(true);
        } else {
          valids.push(false);
        }
      } else if (c.type == "totalOrders") {
      }
      labels.push(c.type);
    });
    let valid;
    if (r.comparison == "&&") {
      valid = !valids.includes(false);
    } else {
      valid = valids.includes(true);
    }
    if (!valid) {
      return;
    }
    let results = [];
    (r.results || []).forEach((re) => {
      if (re.type == "costDiscount") {
        results.push(
          `${r.title}:${r.description}:${re.friendly_title}:sum:${re.value}`
        );
      } else if (re.type == "costDiscountPercentage") {
        results.push(
          `${r.title}:${r.description}:${re.friendly_title}:sum%:${re.value}`
        );
      } else if (re.type == "productDiscount") {
        results.push(
          `${r.title}:${r.description}:${re.friendly_title}:totalC:${re.value}`
        );
      } else if (re.type == "productDiscountPercentage") {
        results.push(
          `${r.title}:${r.description}:${re.friendly_title}:totalC%:${re.value}`
        );
      } else if (re.type == "shippingDiscount") {
        results.push(
          `${r.title}:${r.description}:${re.friendly_title}:shipping.cost:${re.value}`
        );
      } else if (re.type == "shippingDiscountPercentage") {
        results.push(
          `${r.title}:${r.description}:${re.friendly_title}:shipping.cost%:${re.value}`
        );
      } else if (re.type == "freeProduct") {
        results.push(
          `${r.title}:${r.description}:${re.friendly_title}:items:${re.value}`
        );
      }
    });
    return results;
  };

  const applyRules = async (cart) => {
    cart.rules = getRules(cart);
    cart.rullings = [];
    cart.rules = cart.rules.filter((x) => x);
    await Promise.all(
      (cart.rules || []).map(async (rr, rrindex) => {
        let rule = {};
        rule.rulling = 0;
        rule.rulling_shipping = 0;
        rule.rulling_free = "";
        rule.rulling_friendly = [];
        rule.rulling_items = [];
        await Promise.all(
          rr.map(async (r) => {
            rule.title = r.split(":")[0];
            rule.description = r.split(":")[1];
            let friendly_title = r.split(":")[2];
            let key = r.split(":")[3];
            let value = r.split(":")[4];
            if (key == "sum") {
              rule.rulling += parseFloat(value);
              rule.rulling_friendly.push({
                title: friendly_title,
                value: parseFloat(value),
                calculated: (parseFloat(value) || 0).toFixed(2),
              });
            } else if (key == "sum%" && value) {
              rule.rulling += parseFloat(
                (cart.totalC + cart.shipping.cost + cart.payment.cost) *
                  (value / 100)
              );
              rule.rulling_friendly.push({
                title: friendly_title,
                value: value + "%",
                calculated: (
                  parseFloat(
                    (cart.totalC + cart.shipping.cost + cart.payment.cost) *
                      (value / 100)
                  ) || 0
                ).toFixed(2),
              });
            } else if (key == "totalC") {
              rule.rulling += parseFloat(value);
              rule.rulling_friendly.push({
                title: friendly_title,
                value: parseFloat(value),
                calculated: parseFloat(value).toFixed(2),
              });
            } else if (key == "totalC%" && value) {
              rule.rulling += parseFloat(cart.totalC * (value / 100));
              rule.rulling_friendly.push({
                title: friendly_title,
                value: value + "%",
                calculated: (
                  parseFloat(cart.totalC * (value / 100)) || 0
                ).toFixed(2),
              });
            } else if (key == "shipping.cost") {
              rule.rulling_shipping += parseFloat(value);
              rule.rulling_friendly.push({
                title: friendly_title,
                value: value,
                calculated: parseFloat(value).toFixed(2),
              });
            } else if (key == "shipping.cost%" && value) {
              rule.rulling_shipping += parseFloat(
                cart.shipping.cost * (value / 100)
              );
              rule.rulling_friendly.push({
                title: friendly_title,
                value: value + "%",
                calculated: (
                  parseFloat(cart.shipping.cost * (value / 100)) || 0
                ).toFixed(2),
              });
            } else if (key == "items") {
              rule.rulling_free = value;
              try {
                let temp = await $nuxt.$axios.$get(
                  `/items/store/${store.getters.store.id}?store_id=${store.getters.store.id}&barcodes=${rule.rulling_free}`
                );
                if (temp.items && temp.items.docs) {
                  rule.rulling_items = temp.items.docs
                    .map((i) => {
                      i.variation = [
                        {
                          hidden: false,
                          option: true,
                          key: friendly_title || "",
                          value: "ΔΩΡΟ",
                          title: "",
                          type: "price",
                          price: "0",
                        },
                      ];
                      i.quantity = 1;
                      i.ruled = rule.uid;
                      return i;
                    })
                    .filter((i) => {
                      return !(
                        cart.items.filter((ci) => ci.ruled == rule.uid) || []
                      ).length;
                    });
                }
              } catch (error) {}
            }
            return false;
          })
        );
        cart.rullings.push(rule);
        return true;
      })
    );
    return cart;
  };
  const calculateCart = async (cart) => {
    if (store.getters.store.eshop.cartCalculate == "remote") {
      return await remoteCalculateCart(cart);
    }
    if (!store.getters.token) {
      delete cart.rullings;
      cart.items = cart.items.filter((i) => !i.ruled);
    }
    cart.items = cart.items.map((i) => {
      i.quantity = i.quantity < 1 ? 1 : i.quantity;
      return i;
    });
    cart.totalQ = cart.items.reduce((total, i) => {
      return total + parseInt(i.quantity);
    }, 0);
    cart.totalC = cart.items.reduce((total, i) => {
      return total + app.$hyperItem.actualPrice(i, false, i.quantity);
    }, 0);
    cart.vat = cart.items.reduce((total, i) => {
      let ic = app.$hyperItem.actualPrice(i, false, i.quantity);
      return total + ic / (1 + (parseFloat(i.prices.vat) || 24) / 100);
    }, 0);

    cart.shippingLocks = [
      ...cart.items.map((i, index) => {
        return (i.shipping || {}).type;
      }),
    ].filter((x) => x);
    cart.paymentLocks = [
      ...cart.items.map((i, index) => {
        return (i.payment || {}).type;
      }),
    ].filter((x) => x);

    if (
      cart.shipping.options &&
      cart.shipping.options.filter((o) => o.key == "free")
    ) {
      let temp = cart.shipping.options.filter((o) => o.key == "free")[0];
      if (temp && temp.value < cart.totalC) {
        cart.shipping.memory = cart.shipping.memory || cart.shipping.cost;
        cart.shipping.cost = 0;
      } else {
        cart.shipping.cost = cart.shipping.memory || cart.shipping.cost;
      }
    }
    let giftcardCost = 0;
    if (
      cart.giftcard &&
      cart.giftcard.value &&
      cart.giftcard.type == "percentage"
    ) {
      giftcardCost = (cart.totalC * cart.giftcard.value) / 100;
    } else if (cart.giftcard && cart.giftcard.value) {
      giftcardCost = cart.giftcard.value;
    }
    (cart.giftcard || {}).cost = giftcardCost;
    cart.vat = cart.vat;
    let rulling = (cart.rullings || []).reduce((acc, r) => {
      return acc + (r.rulling || 0) + (r.rulling_shipping || 0);
    }, 0);
    cart.sum =
      (cart.totalC || 0) +
      (cart.shipping.cost || 0) +
      (cart.payment.cost || 0) -
      (giftcardCost || 0) -
      (rulling || 0);
    if (store.getters.token) {
      cart = await applyRules(cart);
    }
    return cart;
  };
  const remoteCalculateCart = async (cart) => {
    try {
      let route = process.browser ? $nuxt.$route.name : route.name;

      let temp = await $axios.$post(
        `cart/calculate`,
        {
          store_id: store.getters.store.id,
          cart: cart,
          route,
        },
        {
          headers: {
            Authorization: `Bearer ${store.getters.token}`,
          },
        }
      );
      if (!$cookies.get("cart_id")) {
        $cookies.set("cart_id", temp.id || (temp.cart || {}).id);
      }
      if (temp.shippings) {
        let ss = store.getters.store.shippings.map(
          (s) => {
            s.cost = temp.shippings.find((m) => m._id == s._id).cost;
            return s;
          }
        );
        store.commit('store',{key:'shippings',value:ss})
      }
      if (temp.payments) {
        let pp = store.getters.store.payments.map((s) => {
          s.cost = temp.payments.find((m) => m._id == s._id).cost;
          s.installments = temp.payments.find((m) => m._id == s._id).installments;
          return s;
        });
        store.commit('store',{key:'payments',value:pp})
      }
      if (!temp.cart) {
        return temp;
      }
      //TODO - pick gift
      let picks = (temp.cart.rullings||[]).filter(r=>r.pick_from);
      let uids = picks.map(r=>r.uid);
      let selected = temp.cart.items.filter(i=>uids.includes(i.ruled));
      if(picks.length && selected.length != uids.length){
        $nuxt.$emit("pick_gift", {
          promos: picks,
          open: true,
        });
      }
      return temp.cart;
    } catch (error) {
      console.error(error);
      return cart;
    }
  };
 
  const getCart = (calculate) => {
    let temp = JSON.parse(localStorage.getItem("cart"));
    let default_shipping = defaultShipping(temp);
    if (!temp) {
      temp = {
        items: [],
        totalQ: 0,
        totalC: 0,
        comments: "",
        rullings: [],
        shipping: default_shipping || {
          cost: 0,
          address: {},
        },
        services: [],
        payment: {
          cost: 0,
        },
        parast: {
          type: "receipt",
        },
        user: {},
        checkout: "guest",
        step: 0,
      };
    }
    if (!(temp.shipping||{}).type) {
      temp.shipping = default_shipping;
    }
    if (!temp.shipping2) {
      temp.shipping2 = {};
    }
    if (!temp.billingAddress) {
      temp.billingAddress = false;
    }
    if (calculate) {
      return calculateCart(temp);
    }
    store.commit("cart", temp);
    return temp;
  };

  const addToCart = async (
    titem,
    cart,
    previousQuantity,
    noPop,
    noAlert,
    error
  ) => {
    if (!Array.isArray(titem)) {
      titem = [titem];
    }
    if (!cart) {
      cart = JSON.parse(JSON.stringify(store.getters.cart));
    }
    if (cart.then && typeof cart.then == "function") {
      cart = await cart;
    }
    for (let i = 0; i < titem.length; i++) {
      let item = JSON.parse(JSON.stringify(titem[i]));
      if (!item._id && item.id) {
        item._id = item.id;
      }
      if (!item.id && item._id) {
        item.id = item._id;
      }
      delete item.comment;
      let found = -1;
      if (!item.quantity) {
        item.quantity = 1;
      }
      if (item.stock) {
        item.fromStock = true;
      }
      Object.keys(item).forEach((k) => {
        if (k.includes("tags_")) {
          delete item[k];
        }
      });
      delete item.flat_tags;
      let required = [];
      if (item.options) {
        item.options.forEach((o) => {
          if (o.required) {
            required = [...required, o.key];
          }
        });
        if (item.variation) {
          item.variation = item.variation.filter((v) => v);
          item.variation.forEach((v) => {
            if (required.includes(v.key)) {
              required.splice(required.indexOf(v.key), 1);
            }
          });
        }
        if (required.length && !error) {
          store.commit("optionsRequired", {
            ...item,
            required,
          });
          return false;
        } else if (required.length && error) {
          return "options";
        }
      }
      item.prices.retail_price =
        parseFloat(item.prices.actual_price) || item.prices.retail_price;
      cart.items.forEach((i, index) => {
        if (i.barcode == item.barcode) {
          if (JSON.stringify(i.variation) == JSON.stringify(item.variation)) {
            found = index;
          }
        }
      });
      delete cart.skip;

      if (found < 0) {
        if (previousQuantity) {
          item.quantity = previousQuantity;
        }
        cart.items.push(item);
      } else {
        cart.items[found].quantity =
          parseFloat(cart.items[found].quantity) + parseFloat(item.quantity);
        item = cart.items[found];
        cart.items[found].prices = item.prices;
        cart.items[found].availability = item.availability;
      }
      let img = (item.images||[])[0] || "/fallback.svg";
      if (!noAlert) {
        $clerk.hyperAlert({
          time: 2000,
          title: "Προστέθηκε στο καλάθι!",
          icon: "success",
          html: `<img class="h-16 rounded-hypert" onerror="this.src='/fallback.svg'" src="${img}"/>`,
        });
      }
      item.inCart = true;
      if (process.browser) {
        try {
          window.navigator.vibrate(200);
        } catch (error) {}
      }
      store.commit("addToCartItem", item);
      $nuxt.$emit("informant:addToCart", { item });
      titem[i] = item;
    }
    await saveCart(cart, false);
    if (!noPop) {
      $nuxt.$emit("addToCartPop");
    }
    return (titem || [])[0];
  };

  const addFromBarcodes = async (bcs, skip, noPop, checkPromos) => {
    if (!bcs) {
      return;
    }
    let sv = ``;
    if (store.getters.store.eshop.search.includes("elastic")) {
      sv = "sv/";
    }
    let temp = await $axios.$get(
      `items/${sv}store/${store.getters.store.store_id}?store_id=${store.getters.store.store_id}&barcodes=${bcs}`,
      {
        headers: {
          Authorization: `Bearer ${store.getters.token}`,
        },
      }
    );
    if ((((temp || {}).items || {}).docs || []).length) {
      addToCart(temp.items.docs, false, skip, noPop);
    } else {
      $clerk.hyperAlert({
        time: 2000,
        title: "Δεν βρέθηκαν προϊόντα!",
        icon: "info",
      });
    }
    if (checkPromos && (temp.items.docs || [])[0]) {
      let item = temp.items.docs[0];
      app.$hyperItem.promos(item, "smartbuy", true);
    }
  };

  const removeItem = async (item, cart) => {
    cart.items = cart.items.filter((i) => {
      if (i.id != item.id) {
        return i;
      } else if (i.id == item.id) {
        if (JSON.stringify(i.variation) != JSON.stringify(item.variation)) {
          return i;
        }
      }
    });
    await saveCart(cart);
    $nuxt.$emit("updateCart");
    $nuxt.$emit("informant:removeItem", { item });
    return;
  };

  const checkCartQuantity = async (item, cart) => {
    if (item.moq && item.moq > item.quantity) {
      item.quantity = item.moq;
    } else if (item.maxoq && item.maxoq < item.quantity) {
      item.quantity = item.maxoq;
    }
    await saveCart(cart);
    $nuxt.$emit("updateCart");
    return;
  };
  const increaseCartQuantity = async (item, cart, external) => {
    if (item.maxoq && item.quantity >= item.maxoq) {
      item.quantity = item.maxoq;
      return;
    }
    item.quantity = parseInt(item.quantity) + 1;
    if (external) {
      let i = cart.items.find(
        (i) =>
          i.barcode == item.barcode &&
          JSON.stringify(i.variation) == JSON.stringify(item.variation)
      );
      if (i) {
        i.quantity = item.quantity;
      }
    }
    if(item.giftsMaximum && item.giftsMaximum >= item.quantity){
      app.$hyperItem.promos(item, "smartbuy", true);
    }
    await saveCart(cart);
    $nuxt.$emit("updateCart");
    $nuxt.$emit("informant:addToCart", { item });
    return;
  };
  const decreaseCartQuantity = async (item, cart, external) => {
    if (item.moq && item.quantity <= item.moq) {
      item.quantity = item.moq;
    } else if (item.quantity == 1) {
      item.quantity = 1;
    } else {
      item.quantity = parseInt(item.quantity) - 1;
    }
    if (external) {
      let i = cart.items.find(
        (i) =>
          i.barcode == item.barcode &&
          JSON.stringify(i.variation) == JSON.stringify(item.variation)
      );
      if (i) {
        i.quantity = item.quantity;
      }
    }
    
    if (item.gifts) {
      let totalGifts = item.gifts.reduce((acc, g) => acc + g.quantity, 0);
      if(totalGifts>item.quantity){
        let pool = item.giftsMaximum;
        if (pool > item.quantity) {
          pool = item.quantity;
        }
        if (totalGifts >= item.quantity) {
          for (let j = 0; j < item.gifts.length; j++) {
            if (item.gifts[j].quantity >= pool && pool) {
              pool -= item.gifts[j].quantity;
              item.gifts[j].keep = true;
            }
          }
          item.gifts = item.gifts.filter((g) => g.keep);
        }
      }
    }
    await saveCart(cart);
    $nuxt.$emit("updateCart");
    $nuxt.$emit("informant:removeFromCart", { item });
    return;
  };
  const paypalApproved = (_) => {
    console.info("approved");
    console.warn(_);
  };
  const paypalCompleted = (_) => {
    console.info("completed");
    console.warn(_);
    if (_.state == "approved") {
      _.success = true;
    }
    $nuxt.$emit("sendOrder", { pay_id: _.id,force:true });
  };
  const defaultShipping = (cart) => {
    let defaultShipping = JSON.parse(
      JSON.stringify(
        store.getters.store.shippings.find((s) => s.type == "courier") ||
          store.getters.store.shippings[0]
      ) || "{}"
    );
    defaultShipping.address = {};
    return defaultShipping;
  };

  const skroutzOrder=(cart, order_id) =>{
    // let cart = JSON.parse(JSON.stringify(this.$store.getters.afterCart));
    skroutz_analytics("ecommerce", "addOrder", {
      order_id: cart.order_id, // Order ID. Required.
      revenue:
        cart.shipping.cost +
        cart.items.reduce(
          (acc, i) => acc + app.$hyperItem.actualPrice(i, false, i.quantity),
          0
        ), // Grand Total. Includes Tax and Shipping. Does not include payment costs.
      shipping: cart.shipping.cost, // Total Shipping Cost. Does not include payment costs.
      tax:
        cart.shipping.cost +
        cart.items.reduce(
          (acc, i) => acc + app.$hyperItem.actualPrice(i, false, i.quantity),
          0
        ) *
          0.24, // Total Tax.
      paid_by: cart.payment.type,
      paid_by_descr: cart.payment.title,
    });
    cart.items.forEach((i) => {
      let skroutz_id =
        (i.tags.filter((f) => f.key == "skroutz_id")[0] || {}).value ||
        i.sku ||
        i._id;
      skroutz_analytics("ecommerce", "addItem", {
        order_id: cart.order_id, // Order ID. Required.
        product_id: skroutz_id, // Product ID. Required.
        name: i.description, // Product Name. Required.
        price: app.$hyperItem.actualPrice(i, false), // Price per Unit. Required.
        quantity: i.quantity, // Quantity of Items. Required.
      });
    });
  }
  inject("hyperCart", {
    calculateCart,
    remoteCalculateCart,
    saveCart,
    getCart,
    addToCart,
    addFromBarcodes,
    removeItem,
    checkCartQuantity,
    increaseCartQuantity,
    decreaseCartQuantity,
    syncCart,
    paypalApproved,
    paypalCompleted,
    defaultShipping,
    skroutzOrder
  });
};
